var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-btn',{staticClass:"white--text my-10",attrs:{"tile":"","large":"","color":"light-blue darken-4","width":"166"},on:{"click":function($event){return _vm.toggleAddInfluencerModal(true)}}},[_vm._v(" Add influencer ")]),_c('add-influencer-modal',{attrs:{"doctor-id":_vm.doctorId,"is-open":_vm.isAddInfluencerModalOpen},on:{"cancel":function($event){return _vm.toggleAddInfluencerModal(false)},"success":_vm.onInfluencerAdded}}),_c('data-table',{staticClass:"mt-10",attrs:{"hide-default-footer":"","disable-pagination":"","headers":_vm.$options.headerConfig,"loading":_vm.isLoading,"items":_vm.influencersList},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"light-blue--text text--darken-4 font-weight-medium text-decoration-none"},[_vm._v(" "+_vm._s(item.name)+" ")])])]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"text--darken-2"},[_vm._v(_vm._s(item[_vm.$options.influencerField.EMAIL]))])])]}},{key:"item.slug",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"text--darken-2"},[_vm._v(_vm._s(item[_vm.$options.influencerField.SLUG]))])])]}},{key:"item.fee",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"text--darken-2"},[_vm._v(_vm._s(item[_vm.$options.influencerField.FEE]))])])]}},{key:"item.doctorsParticipation",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"text--darken-2"},[_vm._v(_vm._s(item[_vm.$options.influencerField.DOCTOR_PARTICIPATION]))])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"color":"red","size":"18px"},on:{"click":function($event){return _vm.onDeleteInfluencer(item[_vm.$options.influencerField.ID])}}},[_vm._v("mdi-delete-forever")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }