<template>
  <base-modal :value="isOpen" max-width="420">
    <template v-slot:modal-body>
      <div class="black--text text-center">
        {{ notificationsNumber }} emails have been sent to doctor {{ doctorName }}’s patients.
      </div>
    </template>
    <template v-slot:modal-actions>
      <v-btn
        class="text-capitalize font-weight-bold"
        color="light-blue darken-4"
        text
        @click="onConfirm"
      >
        Close
      </v-btn>
    </template>
  </base-modal>
</template>

<script>
import BaseModal from '@/components/common/BaseModal.vue';

export default {
  name: 'RenewTreatmentNotificationSentModal',
  components: { BaseModal },
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    notificationsNumber: {
      type: Number,
      required: true
    },
    doctorName: {
      type: String,
      required: true
    }
  },
  methods: {
    onConfirm() {
      this.$emit('confirm');
    }
  }
};
</script>
