var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"d-flex mt-10 white fill-height"},[_c('data-table',{attrs:{"headers":_vm.$options.customersHeadersConfig,"items":_vm.customers,"options":_vm.options,"loading":_vm.isLoading,"server-items-length":_vm.serverItemsLength},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-account-outline")]),_c('router-link',{staticClass:"light-blue--text text--darken-4 font-weight-medium text-decoration-none",attrs:{"to":{ name: 'CustomerDetails', params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.firstName)+" "+_vm._s(item.lastName)+" ")])],1)]}},{key:"item.lastActivityDate",fn:function(ref){
var item = ref.item;
return [(item.lastActivityDate)?_c('div',{staticClass:"d-flex align-center"},[_vm._v(" "+_vm._s(item.lastActivityDate)+" ")]):_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"blue-grey--text text--lighten-2 font-italic"},[_vm._v("n/a")])])]}},{key:"item.country",fn:function(ref){
var item = ref.item;
return [(item.countryOfResidenceLabel)?_c('div',{staticClass:"d-flex align-center"},[_vm._v(" "+_vm._s(item.countryOfResidenceLabel)+" ")]):_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"blue-grey--text text--lighten-2 font-italic"},[_vm._v("n/a")])])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status)?_c('div',{staticClass:"d-flex align-center"},[_vm._v(" "+_vm._s(item.status)+" ")]):_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"blue-grey--text text--lighten-2 font-italic"},[_vm._v("n/a")])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }