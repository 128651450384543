<template>
  <data-table
    class="mt-10"
    :headers="$options.ordersTableHeadersConfig"
    :items="orders"
    :options.sync="options"
    :loading="isLoading"
    :server-items-length="serverItemsLength"
  >
    <template #item.status="{ item }">
      <delivery-status-label :delivery-status="item.status" />
    </template>
    <template #item.orderId="{ item }">
      <link-with-icon
        icon-url="/icons/order-icon.svg"
        :link-text="item.orderId"
        :link-to="{ name: 'OrderDetails', params: { id: item.orderId } }"
      />
    </template>
    <template #item.amount="{ item }">
      <span v-if="item.amount">{{ item.amount }}</span>
    </template>
    <template #item.consultationMinutesSpent="{ item }">
      <span v-if="item.consultationMinutesSpent">{{ item.consultationMinutesSpent }} min</span>
    </template>
    <template #item.customerName="{ item }">
      <user-avatar :photo="item.customerPhoto" />
      <router-link
        class="light-blue--text text--darken-4 font-weight-medium text-decoration-none"
        :to="{ name: 'CustomerDetails', params: { id: item.patientId } }"
      >
        {{ item.customerName }}
      </router-link>
    </template>
  </data-table>
</template>

<script>
import DataTable from '@/components/common/DataTable.vue';
import UserAvatar from '@/components/common/UserAvatar.vue';
import DeliveryStatusLabel from '@/components/common/DeliveryStatusLabel.vue';
import LinkWithIcon from '@/components/common/LinkWithIcon.vue';

import { getOrdersList } from '@/api/orders.api';
import {
  formatDateFieldsInArray,
  formatOrdersMoneyFields,
  transformDataTableOptionsForRequest
} from '@/utils';

const DEFAULT_SORT_BY_FIELD = 'orderId';
const ORDERS_TABLE_HEADER_CONFIG = [
  { text: 'Order Id', value: 'orderId' },
  { text: 'Date create', value: 'purchaseDate' },
  { text: 'Status', value: 'status' },
  { text: 'Order ID in Odoo', value: 'odooOrderId' },
  { text: 'Tracking number', value: 'trackingNumber' },
  { text: 'Customer', value: 'customerName' },
  { text: 'Order amount', value: 'amount' },
  { text: 'Consult. fee', value: 'doctorFee' },
  { text: 'Influencer fee', value: 'influencerFee' },
  { text: 'Consult. time', value: 'consultationMinutesSpent' }
];

export default {
  name: 'DoctorOrdersList',
  components: { LinkWithIcon, DeliveryStatusLabel, UserAvatar, DataTable },
  ordersTableHeadersConfig: ORDERS_TABLE_HEADER_CONFIG,
  props: {
    doctorId: {
      type: [Number, String],
      required: true
    }
  },
  data() {
    return {
      options: {},
      isLoading: false,
      orders: [],
      serverItemsLength: 0
    };
  },
  watch: {
    async options() {
      this.orders = await this.getOrders();
    }
  },
  methods: {
    async getOrders() {
      try {
        this.isLoading = true;

        const {
          page,
          sortType = DEFAULT_SORT_BY_FIELD,
          sortDirection,
          size
        } = transformDataTableOptionsForRequest(this.options);

        const {
          data: { orders, ordersTotal }
        } = await getOrdersList({ page, sortType, sortDirection, doctorId: this.doctorId, size });

        this.serverItemsLength = ordersTotal;

        const ordersWithFormattedMoneyFields = formatOrdersMoneyFields(orders);

        return formatDateFieldsInArray(ordersWithFormattedMoneyFields, ['purchaseDate']);
      } catch (error) {
        return [];
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>
