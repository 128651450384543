<template>
  <span :class="deliveryStatusLabelClasses">{{ deliveryStatusLabel }}</span>
</template>

<script>
import { ORDER_DELIVERY_STATUS_CLASSES, ORDERS_DELIVERY_STATUS_LABELS } from '@/constants/orders';

export default {
  name: 'DeliveryStatusLabel',
  props: {
    deliveryStatus: {
      type: String,
      required: true
    }
  },
  computed: {
    deliveryStatusLabelClasses() {
      return ORDER_DELIVERY_STATUS_CLASSES[this.deliveryStatus];
    },
    deliveryStatusLabel() {
      return ORDERS_DELIVERY_STATUS_LABELS[this.deliveryStatus];
    }
  }
};
</script>
