<template>
  <base-modal
    :value="isOpen"
    max-width="420"
    modal-title="Add influencer"
    has-close-button
    @cancel="onCancel"
  >
    <template v-slot:modal-body>
      <v-form ref="addInfluencerForm" class="pt-1 flex-grow-1">
        <base-text-field
          v-model="influencerData.name"
          class="mb-7"
          label="Influencer Name"
          :rules="[$options.validationRules.REQUIRED]"
        />
        <base-text-field
          v-model="influencerData.email"
          class="mb-7"
          label="Email"
          :rules="[$options.validationRules.REQUIRED, $options.validationRules.EMAIL]"
        />
        <base-text-field
          v-model="influencerData.slugAddon"
          class="mb-7"
          label="Questionnaire link slag"
          :error-messages="influencerSlugErrors"
          :rules="[$options.validationRules.REQUIRED]"
        />
        <div class="d-flex">
          <base-text-field
            v-model="influencerData.fee"
            class="mb-5 mr-5"
            label="Fee, %"
            :rules="[$options.validationRules.REQUIRED, $options.consultationFeeFieldsError]"
          />
          <base-text-field
            v-model="influencerData.doctorParticipationFee"
            :min="50"
            class="mb-5"
            label="Doctor’s participation, %"
            :rules="[$options.validationRules.REQUIRED, $options.consultationFeeFieldsError]"
          />
        </div>
      </v-form>
    </template>
    <template v-slot:modal-actions>
      <div class="flex">
        <v-btn
          class="white--text text-capitalize font-weight-bold"
          color="light-blue darken-4"
          tile
          @click="addInfluencer"
        >
          Add influencer
        </v-btn>
        <v-btn
          class="ml-5 text-capitalize font-weight-bold"
          color="light-blue darken-4"
          text
          @click="onCancel"
        >
          Cancel
        </v-btn>
      </div>
    </template>
  </base-modal>
</template>

<script>
import BaseModal from '@/components/common/BaseModal.vue';
import BaseTextField from '@/components/common/BaseTextField.vue';

import { addInfluencer } from '@/api/users.api';

import { VALIDATION_RULES } from '@/constants/common';

const INFLUENCER_FORM_FIELDS = {
  NAME: 'name',
  FEE: 'fee',
  SLUG: 'slugAddon',
  EMAIL: 'email',
  DOCTOR_PARTICIPATION_FEE: 'doctorParticipationFee'
};

const consultationFeeFieldsError = value => {
  const hasInvalidValue = value < 0 || value > 100;

  return !hasInvalidValue || 'Provide a value from 0 to 100';
};

const DEFAULT_INFLUENCER_FORM_FIELDS = {
  [INFLUENCER_FORM_FIELDS.NAME]: '',
  [INFLUENCER_FORM_FIELDS.EMAIL]: '',
  [INFLUENCER_FORM_FIELDS.SLUG]: '',
  [INFLUENCER_FORM_FIELDS.FEE]: 10,
  [INFLUENCER_FORM_FIELDS.DOCTOR_PARTICIPATION_FEE]: 50
};

export default {
  name: 'AddInfluencerModal',
  components: { BaseModal, BaseTextField },
  validationRules: VALIDATION_RULES,
  consultationFeeFieldsError,
  props: {
    doctorId: {
      type: [String, Number],
      required: true
    },
    isOpen: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      influencerData: { ...DEFAULT_INFLUENCER_FORM_FIELDS },
      influencerSlugErrors: []
    };
  },
  methods: {
    resetForm() {
      this.influencerData = { ...DEFAULT_INFLUENCER_FORM_FIELDS };

      this.$refs.addInfluencerForm.resetValidation();
    },
    onCancel() {
      this.resetForm();
      this.$emit('cancel');
    },
    async addInfluencer() {
      const isValid = this.$refs.addInfluencerForm.validate();

      if (!isValid) {
        return;
      }

      try {
        await addInfluencer({ doctorId: this.doctorId, influencerData: this.influencerData });

        this.resetForm();
        this.$emit('success');
      } catch (error) {
        if ((error.message || []).includes('403')) {
          this.influencerSlugErrors = ['influencer suffix should be unique'];
        } else {
          this.influencerSlugErrors = [];

          throw error;
        }
      }
    }
  }
};
</script>
