<template>
  <div>
    <update-clinic-info-modal
      :clinic="selectedClinic"
      edit-clinic-mode
      @update-clinic="updateClinics"
    >
      <template v-slot:activator="{ on }">
        <data-table
          hide-default-footer
          show-expand
          :headers="headers"
          :items="clinics"
          @click:row="editClinic($event, on)"
        >
          <template v-slot:item.clinicName="{ item }">
            <router-link
              class="light-blue--text text--darken-4 font-weight-medium text-decoration-none"
              :to="{}"
            >
              {{ item.clinicName }}
            </router-link>
          </template>

          <template v-slot:item.clinicAddress="{ item }">
            {{ getFullClinicAddress(item) }}
          </template>

          <template v-slot:item.consultationPhone="{ item }">
            {{ getConsultationPhone(item) }}
          </template>

          <template v-slot:item.data-table-expand="{ item }">
            <v-btn
              class="grey lighten-4"
              color="light-blue darken-4"
              icon
              @click.stop="onDeleteClinicButtonClick(item)"
            >
              <v-icon class="text-subtitle-1">
                mdi-close-thick
              </v-icon>
            </v-btn>
          </template>
        </data-table>
      </template>
    </update-clinic-info-modal>
    <update-info-modal
      width="420"
      modal-title="Delete clinic"
      action-button-label="Delete"
      :value="isDeleteModalShown"
      :action-button-color="deleteActionButtonColor"
      @action-button-click="onDeleteClinic"
      @close-modal="closeDeleteModal"
      @cancel="closeDeleteModal"
    />
  </div>
</template>

<script>
import { pathOr } from 'ramda';

import DataTable from '@/components/common/DataTable.vue';
import UpdateClinicInfoModal from '@/components/user-detail-page/clinics/UpdateClinicInfoModal.vue';
import UpdateInfoModal from '@/components/common/UpdateInfoModal.vue';

import { deleteClinic } from '@/api/clinics.api';
import { getFormattedPhone } from '@/utils';
import CountriesService from '@/services/CountriesService';

import { DEFAULT_BUTTON_COLOR } from '@/constants/common';

const CLINICS_TABLE_HEADER_CONFIG = [
  { text: 'Clinic Title', value: 'clinicName' },
  { text: 'Clinic Address', value: 'clinicAddress' },
  { text: 'Consultations phone number', value: 'consultationPhone' },
  { text: '', value: 'data-table-expand' }
];

export default {
  name: 'DoctorClinicsList',
  components: { UpdateClinicInfoModal, DataTable, UpdateInfoModal },
  props: {
    clinics: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      headers: CLINICS_TABLE_HEADER_CONFIG,
      selectedClinic: {},
      isDeleteModalShown: false,
      deleteActionButtonColor: DEFAULT_BUTTON_COLOR,
      deleteClinicId: null
    };
  },
  methods: {
    getConsultationPhone(clinic) {
      const phoneNumber = pathOr('', ['clinicAddress', 'phone'], clinic);
      const isoCode = pathOr('', ['clinicAddress', 'phoneIso'], clinic);

      return getFormattedPhone({ isoCode, phoneNumber });
    },
    getFullClinicAddress({ clinicAddress }) {
      const address = pathOr('', ['address'], clinicAddress);
      const building = pathOr('', ['building'], clinicAddress);
      const apartment = pathOr('', ['apartment'], clinicAddress);
      const city = pathOr('', ['city'], clinicAddress);
      const countryCode = pathOr('', ['countryCode'], clinicAddress);
      const country = CountriesService.getCountryLabel(countryCode);
      const stateCode = pathOr('', ['stateCode'], clinicAddress);
      const zipCode = pathOr('', ['zipCode'], clinicAddress);
      const fullAddress = [zipCode, country, stateCode, city, address, building, apartment];

      return fullAddress.filter(Boolean).join(', ');
    },
    editClinic(clinic, modalActivator) {
      this.selectedClinic = clinic;
      modalActivator.click();
    },
    updateClinics() {
      this.$emit('update-clinics');
    },
    onDeleteClinicButtonClick({ id: deleteClinicId }) {
      this.isDeleteModalShown = true;
      this.deleteClinicId = deleteClinicId;
    },
    async onDeleteClinic() {
      this.isDeleteModalShown = false;

      if (!this.deleteClinicId) {
        return;
      }

      const clinic = this.clinics.find(({ id }) => id === this.deleteClinicId);

      if (clinic) {
        await deleteClinic([this.deleteClinicId]);
        this.updateClinics();
        this.deleteClinicId = null;
      }
    },
    closeDeleteModal() {
      this.isDeleteModalShown = false;
      this.deleteActionButtonColor = DEFAULT_BUTTON_COLOR;
    }
  }
};
</script>
