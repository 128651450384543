<template>
  <article>
    <detail-page-template class="user-details-page blue-grey--text text--darken-3">
      <template v-slot:header>
        <details-page-header :header-info="user" @update-user="updateUserData">
          <template v-slot:actions>
            <dots-dropdown :items="userDotsMenuConfig" @select-item="onDropdownItemSelect" />
          </template>
        </details-page-header>
      </template>
      <template v-slot:content>
        <v-tabs color="blue-grey darken-3" height="30">
          <v-tab class="text-none">Details</v-tab>
          <v-tab-item class="mt-8">
            <v-card flat tile>
              <v-card-text class="px-0">
                <details-tab :user="user" :currency="userCurrency" @update-user="updateUserData" />
              </v-card-text>
            </v-card>

            <template>
              <v-divider class="mt-6" />

              <update-clinic-info-modal :doctor="user" @update-clinic="updateUserData">
                <template v-slot:activator="{ on }">
                  <v-btn
                    tile
                    large
                    color="light-blue darken-4"
                    class="white--text my-10"
                    min-width="166"
                    v-on="on"
                  >
                    Add clinic
                  </v-btn>
                </template>
              </update-clinic-info-modal>

              <doctor-clinics-list :clinics="user.clinicDtos" @update-clinics="updateUserData" />
            </template>
          </v-tab-item>

          <template>
            <v-tab class="text-none">Customers</v-tab>
            <v-tab-item>
              <doctor-customers-list :doctor-id="id" />
            </v-tab-item>

            <v-tab class="text-none">Orders</v-tab>
            <v-tab-item>
              <doctor-orders-list :doctor-id="id" />
            </v-tab-item>

            <v-tab class="text-none">Consultation Fee</v-tab>
            <v-tab-item>
              <doctor-consultation-fee-list :doctor-id="id" />
            </v-tab-item>

            <v-tab class="text-none">Influencers</v-tab>
            <v-tab-item>
              <influencers-list :doctor-id="id" />
            </v-tab-item>
          </template>
        </v-tabs>
      </template>
    </detail-page-template>

    <update-info-modal
      width="420"
      modal-title="Delete account"
      action-button-label="Delete"
      :value="isDeleteModalShown"
      :action-button-color="deleteActionButtonColor"
      @action-button-click="onDeleteUserAccount"
      @close-modal="closeDeleteModal"
      @cancel="closeDeleteModal"
    />
    <component
      :is="renewTreatmentNotificationModal"
      :is-open="true"
      :doctor-name="doctorName"
      :notifications-number="renewTreatmentNotificationsNumber"
      @confirm="onRenewTreatmentNotificationModalConfirm"
      @cancel="onRenewTreatmentNotificationModalClose"
    />
  </article>
</template>

<script>
import { mapGetters } from 'vuex';

import UpdateClinicInfoModal from '@/components/user-detail-page/clinics/UpdateClinicInfoModal.vue';
import DetailPageTemplate from '@/components/tempates/DetailPageTemplate.vue';
import DotsDropdown from '@/components/users-page/DotsDropdown.vue';
import DoctorClinicsList from '@/components/user-detail-page/clinics/DoctorClinicsList.vue';
import DoctorOrdersList from '@/components/user-detail-page/DoctorOrdersList.vue';
import DoctorConsultationFeeList from '@/components/user-detail-page/DoctorConsultationFeeList.vue';
import DetailsTab from '@/components/user/DetailsTab.vue';
import DetailsPageHeader from '@/components/common/DetailsPageHeader.vue';
import UpdateInfoModal from '@/components/common/UpdateInfoModal.vue';
import DoctorCustomersList from '@/components/user-detail-page/DoctorCustomersList.vue';
import RenewTreatmentNotificationWarningModal from '@/components/user/RenewTreatmentNotificationWarningModal.vue';
import RenewTreatmentNotificationSentModal from '@/components/user/RenewTreatmentNotificationSentModal.vue';
import InfluencersList from '@/components/user-detail-page/InfluencersList.vue';

import { getUserById, deleteUser, getUserSettings } from '@/api/users.api';
import {
  sendRenewTreatmentNotificationMessages,
  sendRegainAccessNotificationToDoctor,
  getNumberOfPatientsWhichShouldReceiveRenewTreatmentNotification
} from '@/api/adminPanelUsers.api';

import { buildImageURL } from '@/utils';

import types from '@/store/types';
import { DEFAULT_BUTTON_COLOR, ERROR_BUTTON_COLOR, DOCTOR_LEVEL } from '@/constants/common';

const USER_DOTS_MENU_ITEM = {
  REGAIN_ACCESS: { id: 1, title: 'Regain access' },
  RENEW_TREATMENT_NOTIFICATION: { id: 2, title: 'Renew treatment notification' },
  DELETE_ACCOUNT: { id: 3, title: 'Delete account' }
};

const RENEW_TREATMENT_NOTIFICATION_MODAL = {
  WARNING: RenewTreatmentNotificationWarningModal,
  NOTIFICATIONS_SENT: RenewTreatmentNotificationSentModal
};

export default {
  name: 'UserDetailPage',
  components: {
    InfluencersList,
    DoctorCustomersList,
    DetailsPageHeader,
    UpdateClinicInfoModal,
    DoctorConsultationFeeList,
    DoctorOrdersList,
    DoctorClinicsList,
    DotsDropdown,
    DetailPageTemplate,
    DetailsTab,
    UpdateInfoModal
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    }
  },
  data() {
    return {
      userDotsMenuConfig: [],
      isDeleteModalShown: false,
      deleteActionButtonColor: DEFAULT_BUTTON_COLOR,
      user: {
        name: '',
        photo: null,
        clinicDtos: []
      },
      userCurrency: '',
      renewTreatmentNotificationModal: null,
      renewTreatmentNotificationsNumber: 0
    };
  },
  computed: {
    ...mapGetters({
      isSuperAdmin: types.getters.IS_SUPER_ADMIN
    }),
    doctorName() {
      return this.user.name;
    }
  },
  async mounted() {
    await this.updateUserData();
    this.setUserDotsMenuConfig();
  },
  methods: {
    setUserDotsMenuConfig() {
      const menuConfig = [USER_DOTS_MENU_ITEM.REGAIN_ACCESS];

      if (this.user.doctorLevel === DOCTOR_LEVEL.TWO) {
        menuConfig.push(USER_DOTS_MENU_ITEM.RENEW_TREATMENT_NOTIFICATION);
      }

      if (this.isSuperAdmin) {
        menuConfig.push(USER_DOTS_MENU_ITEM.DELETE_ACCOUNT);
      }

      this.userDotsMenuConfig = menuConfig;
    },
    transformUserData(user) {
      const { photo } = user;

      return {
        ...user,
        photo: buildImageURL({ photo })
      };
    },
    async getUser() {
      const { data } = await getUserById(this.id);

      return this.transformUserData(data);
    },
    async updateUserData() {
      this.user = await this.getUser();

      const { data } = await getUserSettings(this.id);
      this.userCurrency = data.currencyCode;

      this.setUserDotsMenuConfig();
    },
    onDropdownItemSelect({ id }) {
      if (id === USER_DOTS_MENU_ITEM.DELETE_ACCOUNT.id) {
        this.isDeleteModalShown = true;
      }

      if (id === USER_DOTS_MENU_ITEM.RENEW_TREATMENT_NOTIFICATION.id) {
        this.onRenewTreatmentNotificationClick();
      }

      if (id === USER_DOTS_MENU_ITEM.REGAIN_ACCESS.id) {
        sendRegainAccessNotificationToDoctor(this.id);
      }
    },
    async onRenewTreatmentNotificationClick() {
      const { data } = await getNumberOfPatientsWhichShouldReceiveRenewTreatmentNotification(
        this.id
      );

      this.renewTreatmentNotificationsNumber = data;
      this.renewTreatmentNotificationModal = RENEW_TREATMENT_NOTIFICATION_MODAL.WARNING;
    },
    async onRenewTreatmentNotificationModalConfirm() {
      if (this.renewTreatmentNotificationModal === RENEW_TREATMENT_NOTIFICATION_MODAL.WARNING) {
        await sendRenewTreatmentNotificationMessages(this.id);

        this.renewTreatmentNotificationModal =
          RENEW_TREATMENT_NOTIFICATION_MODAL.NOTIFICATIONS_SENT;

        return;
      }

      if (
        this.renewTreatmentNotificationModal ===
        RENEW_TREATMENT_NOTIFICATION_MODAL.NOTIFICATIONS_SENT
      ) {
        this.onRenewTreatmentNotificationModalClose();
      }
    },
    onRenewTreatmentNotificationModalClose() {
      this.renewTreatmentNotificationModal = null;
    },
    async onDeleteUserAccount() {
      try {
        await deleteUser(this.id);

        this.$router.go(-1);
      } catch (error) {
        this.deleteActionButtonColor = ERROR_BUTTON_COLOR;
      }
    },
    closeDeleteModal() {
      this.isDeleteModalShown = false;
      this.deleteActionButtonColor = DEFAULT_BUTTON_COLOR;
    }
  }
};
</script>
