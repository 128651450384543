const getAddressObject = addressComponents => {
  const googleAddressComponent = {
    building: ['street_number'],
    zipCode: ['postal_code'],
    address: ['street_address', 'route'],
    stateCode: [
      'administrative_area_level_1',
      'administrative_area_level_2',
      'administrative_area_level_3',
      'administrative_area_level_4',
      'administrative_area_level_5'
    ],
    city: [
      'locality',
      'sublocality',
      'sublocality_level_1',
      'sublocality_level_2',
      'sublocality_level_3',
      'sublocality_level_4'
    ],
    countryCode: ['country']
  };

  const address = {
    building: '',
    zipCode: '',
    address: '',
    stateCode: '',
    city: '',
    countryCode: ''
  };

  addressComponents.forEach(({ short_name: shortName, long_name: longName, types: geoTypes }) => {
    Object.keys(googleAddressComponent).forEach(key => {
      if (googleAddressComponent[key].includes(geoTypes[0])) {
        if (key === 'countryCode') {
          address[key] = shortName;
        } else {
          address[key] = longName;
        }
      }
    });
  });

  return address;
};

export { getAddressObject };
