<template>
  <address-map-selector :value="coordinates" @input="handleCoordinatesChange" />
</template>

<script>
import { gmapApi } from 'gmap-vue';
import { clone, path } from 'ramda';

import AddressMapSelector from '@/components/common/address/AddressMapSelector.vue';

import { getAddressObject } from '@/utils/googleMaps';

import { DEFAULT_COORDINATES } from '@/constants/common';

const REQUEST_STATUS_SUCCESS = 'OK';

export default {
  name: 'AddressMap',
  components: { AddressMapSelector },
  props: {
    coordinates: {
      type: Object,
      default: () => (clone(DEFAULT_COORDINATES))
    }
  },
  data() {
    return {
      geocoder: null
    };
  },
  computed: {
    google: gmapApi
  },
  methods: {
    handleCoordinatesChange({ lat, lng }) {
      if (this.google && lat && lng) {
        this.changeCoordinates({ lat, lng });
      }
    },
    changeCoordinates(coordinates) {
      const geocoder = new this.google.maps.Geocoder();

      geocoder.geocode({ location: coordinates }, (result, status) => {
        if (status === REQUEST_STATUS_SUCCESS) {
          const { lat: latitude, lng: longitude } = coordinates;
          const addressComponents = path([0, 'address_components'], result);
          const addressObject = getAddressObject(addressComponents);
          const address = {
            ...addressObject,
            latitude,
            longitude
          };

          this.$emit('set-marker', address);
        }
      });
    }
  }
};
</script>
