import httpClient from '@/api/httpClient';

import { ADMIN_PANEL_USERS_ENDPOINT } from '@/constants/endpoints';

const sendRenewTreatmentNotificationMessages = doctorId =>
  httpClient.post(`${ADMIN_PANEL_USERS_ENDPOINT}/${doctorId}/sendRenewMessages`);

const getNumberOfPatientsWhichShouldReceiveRenewTreatmentNotification = doctorId =>
  httpClient.get(`${ADMIN_PANEL_USERS_ENDPOINT}/${doctorId}/sendRenewMessages/patientsCount`);

const sendRegainAccessNotificationToDoctor = doctorId =>
  httpClient.post(`${ADMIN_PANEL_USERS_ENDPOINT}/${doctorId}/sendRegainAccessNotification`);

export {
  sendRenewTreatmentNotificationMessages,
  sendRegainAccessNotificationToDoctor,
  getNumberOfPatientsWhichShouldReceiveRenewTreatmentNotification
};
