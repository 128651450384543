var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('data-table',{staticClass:"mt-10",attrs:{"headers":_vm.$options.ordersTableHeadersConfig,"items":_vm.orders,"options":_vm.options,"loading":_vm.isLoading,"server-items-length":_vm.serverItemsLength},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('delivery-status-label',{attrs:{"delivery-status":item.status}})]}},{key:"item.orderId",fn:function(ref){
var item = ref.item;
return [_c('link-with-icon',{attrs:{"icon-url":"/icons/order-icon.svg","link-text":item.orderId,"link-to":{ name: 'OrderDetails', params: { id: item.orderId } }}})]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [(item.amount)?_c('span',[_vm._v(_vm._s(item.amount))]):_vm._e()]}},{key:"item.consultationMinutesSpent",fn:function(ref){
var item = ref.item;
return [(item.consultationMinutesSpent)?_c('span',[_vm._v(_vm._s(item.consultationMinutesSpent)+" min")]):_vm._e()]}},{key:"item.customerName",fn:function(ref){
var item = ref.item;
return [_c('user-avatar',{attrs:{"photo":item.customerPhoto}}),_c('router-link',{staticClass:"light-blue--text text--darken-4 font-weight-medium text-decoration-none",attrs:{"to":{ name: 'CustomerDetails', params: { id: item.patientId } }}},[_vm._v(" "+_vm._s(item.customerName)+" ")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }