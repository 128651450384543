import httpClient from '@/api/httpClient';

import { CLINICS_ENDPOINT } from '@/constants/endpoints';

const createClinic = clinic => httpClient.post(`${CLINICS_ENDPOINT}/create`, clinic);

const editClinic = clinic => httpClient.patch(`${CLINICS_ENDPOINT}/update`, clinic);

const deleteClinic = clinic => httpClient.post(`${CLINICS_ENDPOINT}/delete`, clinic);

export { createClinic, editClinic, deleteClinic };
