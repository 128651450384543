var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('update-clinic-info-modal',{attrs:{"clinic":_vm.selectedClinic,"edit-clinic-mode":""},on:{"update-clinic":_vm.updateClinics},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('data-table',{attrs:{"hide-default-footer":"","show-expand":"","headers":_vm.headers,"items":_vm.clinics},on:{"click:row":function($event){return _vm.editClinic($event, on)}},scopedSlots:_vm._u([{key:"item.clinicName",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"light-blue--text text--darken-4 font-weight-medium text-decoration-none",attrs:{"to":{}}},[_vm._v(" "+_vm._s(item.clinicName)+" ")])]}},{key:"item.clinicAddress",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getFullClinicAddress(item))+" ")]}},{key:"item.consultationPhone",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getConsultationPhone(item))+" ")]}},{key:"item.data-table-expand",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"grey lighten-4",attrs:{"color":"light-blue darken-4","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.onDeleteClinicButtonClick(item)}}},[_c('v-icon',{staticClass:"text-subtitle-1"},[_vm._v(" mdi-close-thick ")])],1)]}}],null,true)})]}}])}),_c('update-info-modal',{attrs:{"width":"420","modal-title":"Delete clinic","action-button-label":"Delete","value":_vm.isDeleteModalShown,"action-button-color":_vm.deleteActionButtonColor},on:{"action-button-click":_vm.onDeleteClinic,"close-modal":_vm.closeDeleteModal,"cancel":_vm.closeDeleteModal}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }