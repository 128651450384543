import httpClient from '@/api/httpClient';

import { CUSTOMERS_ENDPOINT, PATIENTS_ENDPOINT } from '@/constants/endpoints';

const getCustomersList = requestParams =>
  httpClient.post(`${CUSTOMERS_ENDPOINT}/findPaged`, requestParams);

const getDoctorPatientsList = requestParams =>
  httpClient.post(`${PATIENTS_ENDPOINT}/findPaged`, requestParams);

const getDoctorPatientsCount = requestParams =>
  httpClient.post(`${PATIENTS_ENDPOINT}/count`, requestParams);

const getCustomerById = id => httpClient.get(`${PATIENTS_ENDPOINT}/${id}`);
const getDiagnosticsByCustomerId = id => httpClient.get(`${PATIENTS_ENDPOINT}/${id}/diagnosis/all`);
const getTreatmentsByCustomerId = id => httpClient.get(`${PATIENTS_ENDPOINT}/${id}/treatments/all`);

const updateCustomer = (id, customerInfo) =>
  httpClient.patch(`${PATIENTS_ENDPOINT}/${id}/edit`, customerInfo);

const deleteCustomer = id => httpClient.delete(`${PATIENTS_ENDPOINT}/restricted/delete/${id}`);

export {
  getCustomersList,
  getCustomerById,
  getDiagnosticsByCustomerId,
  getTreatmentsByCustomerId,
  updateCustomer,
  getDoctorPatientsList,
  getDoctorPatientsCount,
  deleteCustomer
};
