<template>
  <update-info-modal
    v-model="isClinicModalShown"
    width="800"
    scrollable
    action-button-label="Save"
    :loading="isLoading"
    :modal-title="clinicModalTitle"
    :action-button-color="actionButtonColor"
    @close-modal="closeModal"
    @action-button-click="onActionButtonClick"
    @cancel="closeModal"
  >
    <template v-slot:activator="{ on }">
      <slot name="activator" :on="on"></slot>
    </template>

    <template v-slot:modal-body>
      <v-form ref="clinicInfoForm" class="pt-1">
        <new-clinic-section v-model="clinicInfo" />
      </v-form>
    </template>
  </update-info-modal>
</template>

<script>
import { clone } from 'ramda';

import UpdateInfoModal from '@/components/common/UpdateInfoModal.vue';
import NewClinicSection from '@/components/user/NewClinicSection.vue';

import { createClinic, editClinic } from '@/api/clinics.api';

import {
  DEFAULT_BUTTON_COLOR,
  ERROR_BUTTON_COLOR,
  DEFAULT_CLINIC_FIELDS
} from '@/constants/common';

export default {
  name: 'UpdateClinicInfoModal',
  components: { NewClinicSection, UpdateInfoModal },
  props: {
    clinic: {
      type: Object,
      default: () => ({})
    },
    editClinicMode: {
      type: Boolean,
      default: false
    },
    doctor: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      isClinicModalShown: false,
      isLoading: false,
      actionButtonColor: DEFAULT_BUTTON_COLOR,
      clinicInfo: clone(DEFAULT_CLINIC_FIELDS)
    };
  },
  computed: {
    clinicModalTitle() {
      return this.editClinicMode ? 'Edit clinic' : 'Add clinic';
    }
  },
  watch: {
    clinic() {
      if (this.editClinicMode) {
        this.clinicInfo = clone(this.clinic);
      }
    },
    doctor() {
      if (!this.editClinicMode) {
        this.clinicInfo.clinicAddress.phoneIso = this.doctor.country;
      }
    }
  },
  methods: {
    closeModal() {
      this.isClinicModalShown = false;
      this.actionButtonColor = DEFAULT_BUTTON_COLOR;

      if (this.editClinicMode) {
        this.clinicInfo = clone(this.clinic);

        return;
      }

      this.clinicInfo = clone(DEFAULT_CLINIC_FIELDS);
    },
    async onActionButtonClick() {
      const { clinicInfoForm } = this.$refs;

      if (!clinicInfoForm.validate()) {
        return;
      }

      this.actionButtonColor = DEFAULT_BUTTON_COLOR;

      try {
        if (this.editClinicMode) {
          await editClinic({ ...this.clinicInfo });
        } else {
          await createClinic({ ...this.clinicInfo, userId: this.doctor.id });
        }

        this.$emit('update-clinic');
        this.closeModal();
      } catch (error) {
        this.actionButtonColor = ERROR_BUTTON_COLOR;
      }
    }
  }
};
</script>
