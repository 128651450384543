<template>
  <main class="d-flex mt-10 white fill-height">
    <data-table
      :headers="$options.customersHeadersConfig"
      :items="customers"
      :options.sync="options"
      :loading="isLoading"
      :server-items-length="serverItemsLength"
    >
      <template v-slot:item.name="{ item }">
        <div class="d-flex align-center">
          <v-icon class="mr-2">mdi-account-outline</v-icon>
          <router-link
            class="light-blue--text text--darken-4 font-weight-medium text-decoration-none"
            :to="{ name: 'CustomerDetails', params: { id: item.id } }"
          >
            {{ item.firstName }} {{ item.lastName }}
          </router-link>
        </div>
      </template>

      <template v-slot:item.lastActivityDate="{ item }">
        <div v-if="item.lastActivityDate" class="d-flex align-center">
          {{ item.lastActivityDate }}
        </div>
        <div v-else class="d-flex align-center">
          <span class="blue-grey--text text--lighten-2 font-italic">n/a</span>
        </div>
      </template>

      <template v-slot:item.country="{ item }">
        <div v-if="item.countryOfResidenceLabel" class="d-flex align-center">
          {{ item.countryOfResidenceLabel }}
        </div>
        <div v-else class="d-flex align-center">
          <span class="blue-grey--text text--lighten-2 font-italic">n/a</span>
        </div>
      </template>

      <template v-slot:item.status="{ item }">
        <div v-if="item.status" class="d-flex align-center">
          {{ item.status }}
        </div>
        <div v-else class="d-flex align-center">
          <span class="blue-grey--text text--lighten-2 font-italic">n/a</span>
        </div>
      </template>
    </data-table>
  </main>
</template>

<script>
import DataTable from '@/components/common/DataTable.vue';

import { getDoctorPatientsList, getDoctorPatientsCount } from '@/api/customers.api';

import { formatDateFieldsInArray, transformDataTableOptionsForRequest } from '@/utils';
import CountriesService from '@/services/CountriesService';

import { DEFAULT_PAGE_NUMBER, DEFAULT_PAGE_SIZE, SORTING_DIRECTIONS } from '@/constants/common';
import { TREATMENT_STATUSES } from '@/constants/treatment';

const DEFAULT_SORT_BY_FIELD = 'lastActivityDate';

const DEFAULT_REQUEST_PARAMS = {
  clinicIds: null,
  doctorId: null,
  firstChar: null,
  patientId: null,
  period: null,
  search: '',
  sortDirection: SORTING_DIRECTIONS.ASC,
  sortName: DEFAULT_SORT_BY_FIELD,
  page: DEFAULT_PAGE_NUMBER,
  size: DEFAULT_PAGE_SIZE
};

const CUSTOMERS_TABLE_HEADER_CONFIG = [
  { text: 'Name', value: 'name', width: '300px' },
  { text: 'Email', value: 'email', width: '300px' },
  { text: 'Registered', value: 'registrationDate', width: '150px' },
  { text: 'Last Activity', value: 'lastActivityDate', width: '150px' },
  { text: 'Country', value: 'country', width: '150px' },
  { text: 'Status', value: 'status', width: '200px' }
];

export default {
  name: 'DoctorCustomersList',
  components: { DataTable },
  customersHeadersConfig: CUSTOMERS_TABLE_HEADER_CONFIG,
  props: {
    doctorId: {
      type: [Number, String],
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      options: {},
      customers: [],
      serverItemsLength: 0
    };
  },
  watch: {
    options() {
      this.updateCustomers();
    }
  },
  methods: {
    async updateCustomers() {
      this.customers = await this.getCustomers();
    },
    transformCustomersList(customersList) {
      const listWithFormattedDate = formatDateFieldsInArray(customersList, [
        'lastActivityDate',
        'registrationDate'
      ]);

      return listWithFormattedDate.map(customer => {
        const { countryOfResidence, status: customerStatus } = customer;
        const status = TREATMENT_STATUSES[customerStatus];
        let countryOfResidenceLabel = null;

        if (countryOfResidence) {
          const customerCountry = countryOfResidence.toUpperCase();
          const customerCountryLabel = CountriesService.getCountryLabel(customerCountry);
          countryOfResidenceLabel = `${customerCountryLabel} (${customerCountry})`;
        }

        return { ...customer, status, countryOfResidenceLabel };
      });
    },
    async getCustomers() {
      try {
        this.isLoading = true;

        const { page, sortDirection } = transformDataTableOptionsForRequest(this.options);

        const requestParams = {
          ...DEFAULT_REQUEST_PARAMS,
          doctorId: this.doctorId,
          sortDirection,
          page
        };

        const { data: customers } = await getDoctorPatientsList(requestParams);
        const { data: customersCount } = await getDoctorPatientsCount(requestParams);

        this.serverItemsLength = customersCount;

        return this.transformCustomersList(customers);
      } catch (error) {
        return [];
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>
