<template>
  <base-modal :value="isOpen" max-width="420">
    <template v-slot:modal-body>
      <div class="black--text">
        Send renew notifications to {{ notificationsNumber }} doctor {{ doctorName }}’s patients?
      </div>
    </template>
    <template v-slot:modal-actions>
      <div class="flex">
        <v-btn
          class="white--text text-capitalize font-weight-bold"
          color="light-blue darken-4"
          tile
          @click="onConfirm"
        >
          Yes, send
        </v-btn>
        <v-btn
          class="ml-5 text-capitalize font-weight-bold"
          color="light-blue darken-4"
          text
          @click="onCancel"
        >
          No
        </v-btn>
      </div>
    </template>
  </base-modal>
</template>

<script>
import BaseModal from '@/components/common/BaseModal.vue';

export default {
  name: 'RenewTreatmentNotificationWarningModal',
  components: { BaseModal },
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    notificationsNumber: {
      type: Number,
      required: true
    },
    doctorName: {
      type: String,
      required: true
    }
  },
  methods: {
    onConfirm() {
      this.$emit('confirm');
    },
    onCancel() {
      this.$emit('cancel');
    }
  }
};
</script>
