<template>
  <div class="new-clinic-section">
    <div class="d-flex mb-5">
      <base-text-field
        class="mr-4"
        :value="value.clinicName"
        label="Clinic title *"
        :rules="$options.requiredFieldRule"
        @change="updateClinicField($event, 'clinicName')"
      />

      <phone-number-input
        v-model="clinicPhoneNumber"
        :default-country="value.clinicAddress.phoneIso"
        placeholder="Clinic phone number *"
        :rules="$options.requiredFieldRule"
        @blur="updateClinicPhoneField"
        @country-changed="updateClinicPhoneIsoField"
      />
    </div>

    <address-map
      class="map-section mb-5"
      :coordinates="markerCoordinates"
      @set-marker="updateAddressFromMap"
    />

    <div class="address-section">
      <base-text-field
        class="mb-5"
        :value="value.clinicAddress.address"
        label="Address line"
        @change="updateClinicAddressField($event, 'address')"
      />

      <base-text-field
        class="mb-5"
        :value="value.clinicAddress.building"
        label="Building"
        @change="updateClinicAddressField($event, 'building')"
      />

      <base-text-field
        class="mb-5"
        :value="value.clinicAddress.apartment"
        label="Apartment"
        @change="updateClinicAddressField($event, 'apartment')"
      />

      <base-text-field
        class="mb-5"
        :value="value.clinicAddress.city"
        label="City"
        @change="updateClinicAddressField($event, 'city')"
      />

      <base-text-field
        class="mb-5"
        :value="value.clinicAddress.stateCode"
        label="State (optional)"
        @change="updateClinicAddressField($event, 'stateCode')"
      />

      <base-text-field
        class="mb-5"
        :value="value.clinicAddress.zipCode"
        label="Zip code"
        @change="updateClinicAddressField($event, 'zipCode')"
      />

      <base-text-field
        class="mb-5"
        :value="countryLabel"
        label="Country"
        :rules="$options.countryRule"
        @blur="updateClinicCountry($event)"
      />

      <base-text-field
        class="mb-5"
        :value="value.clinicAddress.latitude"
        label="Latitude"
        @change="updateClinicAddressField($event, 'latitude')"
      />

      <base-text-field
        class="mb-5"
        :value="value.clinicAddress.longitude"
        label="Longitude"
        @change="updateClinicAddressField($event, 'longitude')"
      />
    </div>

    <base-text-field
      class="align-center mb-5"
      :value="value.clinicPage"
      label="Clinic page at Universkin.com"
      @change="updateClinicField($event, 'clinicPage')"
    >
      <template v-slot:prepend>
        <span>universkin.com/</span>
      </template>
    </base-text-field>

    <base-textarea
      :value="value.clinicDescription"
      label="Clinic description"
      @change="updateClinicField($event, 'clinicDescription')"
    />
  </div>
</template>

<script>
import { clone, pathOr } from 'ramda';

import PhoneNumberInput from '@/components/common/PhoneNumberInput.vue';
import BaseTextField from '@/components/common/BaseTextField.vue';
import BaseTextarea from '@/components/common/BaseTextarea.vue';
import AddressMap from '@/components/common/address/AddressMap.vue';

import CountriesService from '@/services/CountriesService';

import { VALIDATION_RULES, DEFAULT_CLINIC_FIELDS } from '@/constants/common';

export default {
  name: 'NewClinicSection',
  components: { AddressMap, BaseTextarea, BaseTextField, PhoneNumberInput },
  requiredFieldRule: [VALIDATION_RULES.REQUIRED],
  countryRule: [VALIDATION_RULES.COUNTRY],
  props: {
    value: {
      type: Object,
      default: () => clone(DEFAULT_CLINIC_FIELDS)
    }
  },
  data() {
    return {
      clinicPhoneNumber: ''
    };
  },
  computed: {
    countryLabel() {
      return CountriesService.getCountryLabel(this.value.clinicAddress.countryCode);
    },
    markerCoordinates() {
      const lat = +pathOr(0, ['clinicAddress', 'latitude'], this.value);
      const lng = +pathOr(0, ['clinicAddress', 'longitude'], this.value);

      return { lat, lng };
    }
  },
  watch: {
    value(newValue) {
      const newPhoneNumber = pathOr('', ['clinicAddress', 'phone'], newValue);

      if (newPhoneNumber !== this.clinicPhoneNumber) {
        this.clinicPhoneNumber = newPhoneNumber;
      }
    }
  },
  mounted() {
    this.clinicPhoneNumber = this.value.clinicAddress.phone;
  },
  methods: {
    updateAddressFromMap(addressFromMap) {
      const clinicAddress = { ...this.value.clinicAddress, ...addressFromMap };
      this.$emit('input', { ...this.value, clinicAddress });
    },
    updateClinicField(newValue, fieldName) {
      this.$emit('input', { ...this.value, [fieldName]: newValue });
    },
    updateClinicCountry({ target: { value: countryName } }) {
      const countryCode = CountriesService.getCountryISOByName(countryName);
      const clinicAddress = { ...this.value.clinicAddress, countryCode };
      this.$emit('input', { ...this.value, clinicAddress });
    },
    updateClinicAddressField(newValue, fieldName) {
      const clinicAddress = { ...this.value.clinicAddress, [fieldName]: newValue };
      this.$emit('input', { ...this.value, clinicAddress });
    },
    updateClinicPhoneField() {
      this.updateClinicAddressField(this.clinicPhoneNumber, 'phone');
    },
    updateClinicPhoneIsoField({ iso2 }) {
      this.updateClinicAddressField(iso2, 'phoneIso');
    }
  }
};
</script>

<style lang="scss" scoped>
.new-clinic-section {
  padding: 10px;
}

.address-section {
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
}

.map-section {
  height: 250px;
}
</style>
