<template>
  <div class="d-flex flex-column">
    <div class="d-flex align-center mb-3">
      <gmap-autocomplete class="mr-3 px-3 autocomplete" @place_changed="setPlace" />
      <v-btn tile color="light-blue darken-4" class="find-button white--text" @click="addMarker">
        Find
      </v-btn>
    </div>
    <gmap-map
      ref="gmap"
      class="google-map"
      :options="$options.mapOptions"
      :center="center"
      :zoom="zoomLevel"
      @click="updateMarkerCoordinates"
    >
      <gmap-marker v-if="shouldShowMarker" :position="markerPosition" draggable @dragend="updateMarkerCoordinates" />
    </gmap-map>
  </div>
</template>

<script>
import { clone } from 'ramda';

import { DEFAULT_COORDINATES } from '@/constants/common';

const MAP_ZOOM = {
  OVERVIEW: 1,
  DETAILED: 12
};

const MAP_OPTIONS = {
  mapTypeControl: false,
  streetViewControl: false,
  autocomplete: true
};

export default {
  name: 'AddressMapSelector',
  mapOptions: MAP_OPTIONS,
  props: {
    value: {
      type: Object,
      default: () => clone(DEFAULT_COORDINATES)
    }
  },
  data() {
    return {
      center: clone(DEFAULT_COORDINATES),
      marker: clone(DEFAULT_COORDINATES),
      currentPlace: null
    };
  },
  computed: {
    shouldShowMarker() {
      const { lat, lng } = this.value;
      return lat && lng;
    },
    markerPosition() {
      const { lat, lng } = this.marker;
      return !lat || !lng ? null : this.marker;
    },
    zoomLevel() {
      if (!this.value) {
        return MAP_ZOOM.OVERVIEW;
      }

      const { lat, lng } = this.value;

      return lat && lng ? MAP_ZOOM.DETAILED : MAP_ZOOM.OVERVIEW;
    }
  },
  watch: {
    marker({ lat, lng }) {
      if (lat && lng) {
        this.$emit('input', { lat, lng });
      }
    },
    value: {
      handler: 'geolocate',
      immediate: true
    }
  },
  methods: {
    setPlace(place) {
      this.currentPlace = place;
    },
    addMarker() {
      if (this.currentPlace) {
        const markerCoordinates = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng()
        };

        this.marker = markerCoordinates;
        this.$refs.gmap.panTo(markerCoordinates);
      }
    },
    updateMarkerCoordinates({ latLng }) {
      this.marker = { lat: latLng.lat(), lng: latLng.lng() };
    },
    geolocate() {
      const { gmap } = this.$refs;

      if (!this.value || !gmap) {
        return;
      }

      const { lat, lng } = this.value;

      if (lat && lng) {
        const coordinates = { lat, lng };

        this.center = coordinates;
        this.marker = coordinates;
        this.$refs.gmap.panTo(coordinates);

        return;
      }

      navigator.geolocation.getCurrentPosition(position => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.autocomplete {
  width: 100%;
  height: 40px;
  border: 1px solid #90a4ae;
  border-radius: 5px;

  &:hover {
    border-color: #000000;
  }

  &:active {
    border: 1px;
  }
}

.find-button {
  height: 40px;
}

.google-map {
  width: 100%;
  height: 200px;
}
</style>
