<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <data-table
    class="mt-10"
    hide-default-footer
    disable-pagination
    :headers="$options.consultationFeeTableHeadersConfig"
    :loading="isLoading"
    :items="consultationFeeList"
  >
    <template v-slot:item.month="{ item }">
      <div class="d-flex flex-column py-2">
        <span class="body-1">{{ item.monthName }}</span>
        <span class="blue-grey--text text--lighten-2 caption">{{ item.year }}</span>
      </div>
    </template>
    <template v-slot:item.invoice="{ item }">
      <div class="d-flex">
        <div v-if="item.hasInvoice" class="mr-3">
          <span class="text--darken-2 mr-3">{{ getPdfFileName(item) }}</span>
          <a
            class="orange--text text--darken-2 font-weight-medium"
            href="#"
            @click="downloadInvoice(item)"
            >Download</a
          >
        </div>
        <a
          class="orange--text text--darken-2 font-weight-medium"
          href="#"
          @click="sendInvoice(item)"
          >Upload</a
        >
      </div>
    </template>
    <template v-slot:item.paid="{ item }">
      <span v-if="item.paid" class="green--text text--darken-1 font-weight-medium">
        Paid {{ item.paymentDate }}
      </span>
      <div v-else class="font-weight-medium">
        <a
          v-if="item.hasInvoice"
          class="orange--text text--darken-2"
          href="#"
          @click="markInvoiceAsPaid(item)"
          >Mark as paid</a
        >
        <span v-else class="blue-grey--text text--lighten-2">No</span>
      </div>
    </template>
  </data-table>
</template>

<script>
import { range, pathOr } from 'ramda';

import DataTable from '@/components/common/DataTable.vue';

import {
  uploadConsultationFeeInvoice,
  downloadConsultationFeeInvoice,
  getConsultationFeeStatistics,
  setInvoiceAsPaid
} from '@/api/consultationFee.api';

import {
  getCurrentFullYear,
  getFullMonthName,
  getFormattedDate,
  downloadFile,
  isPdfFile
} from '@/utils';

const CONSULTATION_FEE_TABLE_HEADER_CONFIG = [
  { text: 'Month', value: 'month' },
  { text: 'Online orders', value: 'offersPaid' },
  { text: 'Consult. time', value: 'consultationTime' },
  { text: "Dr's fee", value: 'fee' },
  { text: 'Invoice', value: 'invoice' },
  { text: 'Paid', value: 'paid' }
];

const CONSULTATION_CALCULATION_START_YEAR = 2020;

export default {
  name: 'DoctorConsultationFeeList',
  components: { DataTable },
  consultationFeeTableHeadersConfig: CONSULTATION_FEE_TABLE_HEADER_CONFIG,
  props: {
    doctorId: {
      type: [Number, String],
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      consultationFeeList: [],
      invoiceFileInput: null,
      uploadInvoiceMonth: null,
      uploadInvoiceYear: null,
      currentYear: null
    };
  },
  async mounted() {
    this.currentYear = getCurrentFullYear();
    this.consultationFeeList = await this.getConsultationFeeStatistics();
    this.initFileInput();
  },
  methods: {
    async getConsultationFeeStatistics() {
      try {
        this.isLoading = true;

        const yearsToLoad = range(CONSULTATION_CALCULATION_START_YEAR, this.currentYear + 1);
        const statisticsToLoad = yearsToLoad.map(year =>
          getConsultationFeeStatistics(this.doctorId, year)
        );

        const statistics = await Promise.all(statisticsToLoad);

        const allStatistics = statistics.reduce((statisticsAccumulator, yearStatistic) => {
          const monthlyStatistic = pathOr([], ['data', 'monthlyStatistic'], yearStatistic);
          return [...monthlyStatistic, ...statisticsAccumulator];
        }, []);

        return this.transformConsultationFeeData(allStatistics);
      } catch (error) {
        return [];
      } finally {
        this.isLoading = false;
      }
    },
    transformConsultationFeeData(feeData) {
      return feeData.map(monthStatistic => {
        const {
          consultationAmountInNationalCurrency: fee,
          doctorNationalCurrency: currency,
          consultationTime,
          month,
          paymentDate
        } = monthStatistic;

        return {
          ...monthStatistic,
          fee: `${currency}${fee}`,
          consultationTime: this.getFormattedTime(consultationTime),
          monthName: getFullMonthName(month),
          paymentDate: paymentDate ? getFormattedDate(paymentDate) : null
        };
      });
    },
    initFileInput() {
      const fileInput = document.createElement('input');

      fileInput.type = 'file';
      fileInput.addEventListener('change', ({ target: { files } }) => {
        this.uploadFiles(files);
      });

      this.invoiceFileInput = fileInput;
    },
    getFormattedTime(consultationTime) {
      return `${consultationTime}min`;
    },
    getPdfFileName({ month, year }) {
      return `invoice_${this.doctorId}_${month}_${year}.pdf`;
    },
    sendInvoice({ month, year }) {
      this.uploadInvoiceMonth = month;
      this.uploadInvoiceYear = year;
      this.invoiceFileInput.click();
    },
    async uploadFiles(files) {
      if (!files || files.length === 0) {
        return;
      }

      const [file] = files;

      if (!isPdfFile(file)) {
        return;
      }

      await this.uploadInvoice(file);
      this.consultationFeeList = await this.getConsultationFeeStatistics();
    },
    uploadInvoice(file) {
      return uploadConsultationFeeInvoice({
        doctorId: this.doctorId,
        month: this.uploadInvoiceMonth,
        year: this.uploadInvoiceYear,
        file
      });
    },
    async downloadInvoice({ month, year }) {
      const { data } = await downloadConsultationFeeInvoice({
        doctorId: this.doctorId,
        month,
        year
      });

      if (!data) {
        return;
      }

      downloadFile(data, this.getPdfFileName({ month, year }));
    },
    async markInvoiceAsPaid({ month, year }) {
      try {
        await setInvoiceAsPaid({
          doctorId: this.doctorId,
          month,
          year
        });
      } finally {
        this.consultationFeeList = await this.getConsultationFeeStatistics();
      }
    }
  }
};
</script>
