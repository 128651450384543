import axios from 'axios';

import httpClient from '@/api/httpClient';

import LocalStorageService from '@/services/LocalStorageService';

import { INVOICE_ENDPOINT, USERS_ENDPOINT } from '@/constants/endpoints';

const uploadConsultationFeeInvoice = ({ doctorId, month, year, file }) => {
  const uploadUrl = `${INVOICE_ENDPOINT}/${doctorId}/${month}/${year}/upload`;
  const formData = new FormData();
  const fileName = `invoice${doctorId}${month}${year}`;

  formData.append('file', file, fileName);

  return httpClient.post(uploadUrl, formData, { 'content-Type': 'multipart/form-data' });
};

const downloadConsultationFeeInvoice = ({ doctorId, month, year }) => {
  const token = LocalStorageService.getToken();

  return axios({
    baseURL: process.env.VUE_APP_BASE_URL,
    url: `${INVOICE_ENDPOINT}/${doctorId}/${month}/${year}/download`,
    method: 'POST',
    responseType: 'arraybuffer',
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const getConsultationFeeStatistics = (doctorId, yearToCalculateFee) =>
  httpClient.get(
    `${USERS_ENDPOINT}/${doctorId}/feeStatistic?yearToCalculateFee=${yearToCalculateFee}`
  );

const setInvoiceAsPaid = ({ doctorId, month, year }) =>
  httpClient.post(`${INVOICE_ENDPOINT}/${doctorId}/${month}/${year}/setPaid`);

export {
  uploadConsultationFeeInvoice,
  downloadConsultationFeeInvoice,
  getConsultationFeeStatistics,
  setInvoiceAsPaid
};
