<template>
  <div>
    <v-btn
      tile
      large
      color="light-blue darken-4"
      class="white--text my-10"
      width="166"
      @click="toggleAddInfluencerModal(true)"
    >
      Add influencer
    </v-btn>
    <add-influencer-modal
      :doctor-id="doctorId"
      :is-open="isAddInfluencerModalOpen"
      @cancel="toggleAddInfluencerModal(false)"
      @success="onInfluencerAdded"
    />

    <data-table
      class="mt-10"
      hide-default-footer
      disable-pagination
      :headers="$options.headerConfig"
      :loading="isLoading"
      :items="influencersList"
    >
      <template v-slot:item.name="{ item }">
        <div class="d-flex">
          <span class="light-blue--text text--darken-4 font-weight-medium text-decoration-none">
            {{ item.name }}
          </span>
        </div>
      </template>
      <template v-slot:item.email="{ item }">
        <div class="d-flex">
          <span class="text--darken-2">{{ item[$options.influencerField.EMAIL] }}</span>
        </div>
      </template>
      <template v-slot:item.slug="{ item }">
        <div class="d-flex">
          <span class="text--darken-2">{{ item[$options.influencerField.SLUG] }}</span>
        </div>
      </template>
      <template v-slot:item.fee="{ item }">
        <div class="d-flex">
          <span class="text--darken-2">{{ item[$options.influencerField.FEE] }}</span>
        </div>
      </template>
      <template v-slot:item.doctorsParticipation="{ item }">
        <div class="d-flex">
          <span class="text--darken-2">{{
            item[$options.influencerField.DOCTOR_PARTICIPATION]
          }}</span>
        </div>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon
          class="ml-1 cursor-pointer"
          color="red"
          size="18px"
          @click="onDeleteInfluencer(item[$options.influencerField.ID])"
          >mdi-delete-forever</v-icon
        >
      </template>
    </data-table>
  </div>
</template>

<script>
import AddInfluencerModal from '@/components/user/AddInfluencerModal.vue';
import DataTable from '@/components/common/DataTable.vue';

import { getInfluencers, deleteInfluencer } from '@/api/users.api';

const INFLUENCER_FIELD = {
  ID: 'influencerId',
  DOCTOR_ID: 'userId',
  NAME: 'name',
  EMAIL: 'email',
  SLUG: 'slugAddon',
  FEE: 'fee',
  DOCTOR_PARTICIPATION: 'doctorParticipationFee'
};

const HEADER_CONFIG = [
  { text: 'Influencer Name', value: INFLUENCER_FIELD.NAME, sortable: false },
  { text: 'Email', value: INFLUENCER_FIELD.EMAIL, sortable: false },
  { text: 'Questionnaire link slug', value: INFLUENCER_FIELD.SLUG, sortable: false },
  { text: 'Fee, %', value: INFLUENCER_FIELD.FEE, sortable: false },
  {
    text: 'Doctor participation, %',
    value: INFLUENCER_FIELD.DOCTOR_PARTICIPATION,
    sortable: false
  },
  { text: 'Actions', value: 'actions', sortable: false }
];

export default {
  name: 'InfluencersList',
  components: {
    AddInfluencerModal,
    DataTable
  },
  influencerField: INFLUENCER_FIELD,
  headerConfig: HEADER_CONFIG,
  props: {
    doctorId: {
      type: [String, Number],
      required: true
    }
  },
  data() {
    return {
      isAddInfluencerModalOpen: false,
      isLoading: false,
      influencersList: []
    };
  },
  async mounted() {
    await this.loadInfluencers();
  },
  methods: {
    async loadInfluencers() {
      const { data } = await getInfluencers(this.doctorId);

      this.influencersList = data;
    },
    toggleAddInfluencerModal(isOpen) {
      this.isAddInfluencerModalOpen = isOpen;
    },
    async onInfluencerAdded() {
      this.toggleAddInfluencerModal(false);
      await this.loadInfluencers();
    },
    async onDeleteInfluencer(id) {
      await deleteInfluencer(id);
      await this.loadInfluencers();
    }
  }
};
</script>
