<template>
  <section>
    <div class="tab d-flex justify-space-between">
      <div v-for="{ fields, key } in columns" :key="key" class="tab__column mr-2">
        <div v-for="{ label, value, isNone } in fields" :key="label" class="mb-5">
          <div class="grey--text text--darken-1">{{ label }}</div>
          <div v-if="!isNone" class="grey--text text--darken-3">{{ value }}</div>
          <div v-else class="grey--text text--lighten-1 font-italic">none</div>
        </div>
      </div>
    </div>

    <update-user-info-modal
      :user-info="user"
      edit-user-mode
      @update-user="updateUserInfo"
      @create-user="updateUserInfo"
    >
      <template v-slot:activator="{ on }">
        <v-btn tile large outlined color="light-blue darken-4" class="white--text" v-on="on">
          <v-icon left>mdi-pencil</v-icon> Edit
        </v-btn>
      </template>
    </update-user-info-modal>
  </section>
</template>

<script>
import DetailsField from '@/models/DetailsField';
import UpdateUserInfoModal from '@/components/user/UpdateUserInfoModal.vue';

import CountriesService from '@/services/CountriesService';
import { getLabelFromBoolean, getFormattedPhone } from '@/utils';

import { LOCALE } from '@/constants/locales';
import { COUNTRY_ISO } from '@/constants/countries';

const GENERAL_FIELD_LABEL = {
  REGISTERED: 'Registered',
  LOGIN: 'Login',
  NAME: 'Name',
  EMAIL: 'Email',
  SECONDARY_EMAIL: 'Sec. email',
  OFFICE_PHONE: 'Office phone number',
  PRIVATE_PHONE: 'Phone number',
  LANGUAGE: 'Language',
  COUNTRY: 'Country',
  STATE: 'State',
  RIGHTS: 'Rights'
};

const ACCOUNT_FIELD_LABEL = {
  DEMO_ACCOUNT: 'Demo account',
  DOCTORS_LEVEL: 'Doctor’s level',
  HOURLY_RATE: 'Hourly rate',
  URL_SUFFIX: 'Url suffix',
  PERSONAL_PAGE: 'Personal page',
  DISABLE_PFEN: 'DTCND',
  ENABLE_MICRONEEDLING: 'S.tep',
  MULTI_COUNTRY_SELLING_AVAILABLE: 'Multi Country Selling',
  MEDICAL_CONCENTRATION_BLOCKED: 'Lock medical grade concentration',
  AUTOPILOT_ENABLED: 'Autopilot'
};

const SMS_FIELDS = [
  { label: 'Custom sms limit', fieldName: 'customSMSLimit' },
  { label: 'Custom sms used', fieldName: 'customSMSUsed' },
  { label: 'Mandatory sms used', fieldName: 'mandatorySMSUsed' }
];

const HOURLY_RATE_FIELD_INDEX = 2;

export default {
  name: 'DetailsTab',
  components: { UpdateUserInfoModal },
  props: {
    user: {
      type: Object,
      default: () => {}
    },
    currency: {
      type: String,
      default: ''
    }
  },
  computed: {
    hasWebshopAccess() {
      return this.user.doctorLevel > 1;
    },
    generalFields() {
      const {
        registeredDate,
        username,
        name,
        email,
        secondaryEmail,
        officePhoneIso,
        officePhone,
        locale,
        country,
        state
      } = this.user;

      const officePhoneValue = getFormattedPhone({
        isoCode: officePhoneIso,
        phoneNumber: officePhone
      });

      const privatePhoneDetailsField = this.getPrivatePhoneDetailsField(this.user);

      const generalFields = [
        new DetailsField({
          label: GENERAL_FIELD_LABEL.REGISTERED,
          value: registeredDate,
          isNone: this.checkIfNone(registeredDate)
        }),
        new DetailsField({ label: GENERAL_FIELD_LABEL.LOGIN, value: username }),
        new DetailsField({ label: GENERAL_FIELD_LABEL.NAME, value: name }),
        new DetailsField({ label: GENERAL_FIELD_LABEL.EMAIL, value: email }),
        new DetailsField({
          label: GENERAL_FIELD_LABEL.SECONDARY_EMAIL,
          value: secondaryEmail,
          isNone: this.checkIfNone(secondaryEmail)
        }),
        new DetailsField({
          label: GENERAL_FIELD_LABEL.OFFICE_PHONE,
          value: officePhoneValue,
          isNone: this.checkIfNone(officePhoneValue)
        }),
        privatePhoneDetailsField,
        new DetailsField({
          label: GENERAL_FIELD_LABEL.LANGUAGE,
          value: LOCALE[locale]
        }),
        new DetailsField({
          label: GENERAL_FIELD_LABEL.COUNTRY,
          value: `${CountriesService.getCountryLabel(country)} (${country})`
        })
      ];

      const fieldState = new DetailsField({
        label: GENERAL_FIELD_LABEL.STATE,
        value: state,
        isNone: this.checkIfNone(state)
      });

      if (country === COUNTRY_ISO.UNITED_STATES) {
        generalFields.push(fieldState);
      }

      return generalFields;
    },
    accountFields() {
      const {
        demoAccount,
        doctorLevel,
        consultationCost,
        doctorSlug,
        personalPage,
        disablePFEN,
        microneedlingEnabled,
        multiCountrySellingAvailable,
        medicalConcentrationBlocked,
        autopilotEnabled
      } = this.user;

      const defaultDetailsFields = [
        new DetailsField({
          label: ACCOUNT_FIELD_LABEL.DEMO_ACCOUNT,
          value: getLabelFromBoolean(demoAccount)
        }),
        new DetailsField({ label: ACCOUNT_FIELD_LABEL.DOCTORS_LEVEL, value: doctorLevel }),
        new DetailsField({ label: ACCOUNT_FIELD_LABEL.URL_SUFFIX, value: doctorSlug }),
        new DetailsField({
          label: ACCOUNT_FIELD_LABEL.PERSONAL_PAGE,
          value: personalPage,
          isNone: this.checkIfNone(personalPage)
        }),
        new DetailsField({
          label: ACCOUNT_FIELD_LABEL.DISABLE_PFEN,
          value: getLabelFromBoolean(disablePFEN)
        }),
        new DetailsField({
          label: ACCOUNT_FIELD_LABEL.ENABLE_MICRONEEDLING,
          value: getLabelFromBoolean(microneedlingEnabled)
        }),
        new DetailsField({
          label: ACCOUNT_FIELD_LABEL.MULTI_COUNTRY_SELLING_AVAILABLE,
          value: getLabelFromBoolean(multiCountrySellingAvailable)
        }),
        new DetailsField({
          label: ACCOUNT_FIELD_LABEL.MEDICAL_CONCENTRATION_BLOCKED,
          value: getLabelFromBoolean(medicalConcentrationBlocked)
        })
      ];

      if (consultationCost) {
        defaultDetailsFields.splice(
          HOURLY_RATE_FIELD_INDEX,
          0,
          new DetailsField({
            label: `${ACCOUNT_FIELD_LABEL.HOURLY_RATE} ${this.currency}`,
            value: consultationCost
          })
        );
      }

      if (this.hasWebshopAccess) {
        defaultDetailsFields.push(
          new DetailsField({
            label: ACCOUNT_FIELD_LABEL.AUTOPILOT_ENABLED,
            value: getLabelFromBoolean(autopilotEnabled)
          })
        );
      }

      return defaultDetailsFields;
    },
    smsFields() {
      return SMS_FIELDS.map(field => {
        const value = this.user[field.fieldName];

        return {
          label: field.label,
          value
        };
      });
    },
    columns() {
      return [
        {
          fields: this.generalFields,
          key: 'general-fields'
        },
        {
          fields: this.accountFields,
          key: 'account-fields'
        },
        {
          fields: this.smsFields,
          key: 'sms-fields'
        }
      ];
    }
  },
  methods: {
    checkIfNone(value) {
      return !value || value === 'null';
    },
    getPrivatePhoneDetailsField({ privatePhoneIso, privatePhone }) {
      const privatePhoneValue = getFormattedPhone({
        isoCode: privatePhoneIso,
        phoneNumber: privatePhone
      });

      return new DetailsField({
        label: GENERAL_FIELD_LABEL.PRIVATE_PHONE,
        value: privatePhoneValue,
        isNone: this.checkIfNone(privatePhoneValue)
      });
    },
    updateUserInfo() {
      this.$emit('update-user');
    }
  }
};
</script>

<style lang="scss" scoped>
.tab {
  max-width: 750px;
}
</style>
